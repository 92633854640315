<template>
    <v-container>
        <h1>Welcome!</h1>
        <p>
            Thank you for choosing to take part in this study!
        </p>
        <p>
            This is the booking process for taking part in a game. There are 4 steps and it will take about 10 minutes.
        </p>

        <p class="mb-0">
            Before you go further, please ensure that you have carefully read the following;
        </p>
        <v-row>
            <v-col cols="12" sm="6">
                <v-btn block outlined color="primary" :href="pisUrl" target="_blank">
                    <v-icon left>mdi-open-in-new</v-icon>
                    Participant Information Sheet
                </v-btn>
            </v-col>
            <v-col cols="12" sm="6">
                <v-btn block outlined color="primary" :href="cfUrl" target="_blank">
                    <v-icon left>mdi-open-in-new</v-icon>
                    Consent Form
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data: () => ({
        pisUrl: process.env.VUE_APP_GAME_URL + "/information_sheet.pdf",
        cfUrl: process.env.VUE_APP_GAME_URL + "/consent_form.pdf"
    })
}
</script>
