import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL || "http://localhost:8000"
});

console.log("Using API URL", process.env.VUE_APP_API_URL || "http://localhost:8000");

async function getBookings() {
    try {
        const res = await instance.get("/bookings");
        return res.data;
    } catch (err) {
        handleError(err);
        return;
    }
}

async function createBooking(cohort, name, email, answers) {
    try {
        const res = await instance.post("/bookings", {
            cohort,
            consent: {
                name,
                email
            },
            about: {
                answers
            }
        });
        return res.data;
    } catch (err) {
        handleError(err);
        return;
    }
}

function handleError(error) {
    const errorMessage = parseError(error);
    console.error("** API ERROR! **", errorMessage, error);
    alert(`A problem occurred:\n${errorMessage}\n\nPlease refresh and try again or contact the lead researcher if the problem persists.`);
}

function parseError(error) {
    if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx

        // Check if the response from the server is JSON-like
        if (error.response.data != null && typeof error.response.data === 'object') {
            const errorObject = error.response.data;

            if (errorObject.detail && typeof errorObject.detail === "string") {
                return `The server returned an error [${error.response.status}]: ${errorObject.detail}`;
            }
        } else {
            return `The server returned an error [${error.response.status}]: ${error.response.data}`;
        }
    } else if (error.request) {
        return 'The server did not respond!';
    } else {
        return `There was an unexpected error: ${error.message}`;
    }
}

export {
  getBookings, createBooking
}
