<template>
    <v-container>
        <h2><b>Step 1:</b> Pick a date for your game</h2>
        <v-select v-model="cohort" label="Date" :items="bookings" item-value="cohort" :item-text="humanDate" outlined></v-select>
    </v-container>
</template>

<script>
import { DateTime } from 'luxon';

export default {
  props: {
    bookings: Array,
  },
  data: () => ({
    cohort: ""
  }),
  methods: {
    humanDate(i) {
      const date = DateTime.fromISO(i.date + "T" + i.time);
      return date.toLocaleString(DateTime.DATETIME_FULL) + ` (${i.slots_left} slots left)`;
    }
  },
  watch: {
    cohort: {
      deep: true,
      handler() {
        this.$emit("updateCohort", this.cohort);
      }
    }
  }
}
</script>
