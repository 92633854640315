<template>
    <v-container>
        <h1><b>Step 4:</b> Game Details</h1>
        <p>
            That's it! Here are your game details:
        </p>
        <p style="font-size: 1.3em !important;">
            <b>Participant Code <small>(case sensitive)</small>:</b> {{ booking.participant_code }}<br>
            <b>Game Slot:</b> {{ readableDate }}<br>
            <b>Zoom Meeting ID:</b> {{ booking.meeting_id }}<br>
            <b>Zoom Meeting Password:</b> {{ booking.meeting_password }}
        </p>
        <div>
            <v-btn color="primary" class="mr-2 mb-2" :href="googleEvent" small target="_blank">Add to Google Calendar</v-btn>
            <v-btn color="primary" class="mb-2" :href="iCalEvent" small target="_blank">Add to Other Calendar</v-btn>
        </div>
        <p>
            <b>Please keep this information safe.</b> As we are working hard to
            maintain your anonymity, we <b>cannot</b> help you recover this if
            you lose it. Make sure you come back to this website with plenty of
            time to log in before the game starts (we recommend 5-10 minutes).
        </p>
    </v-container>
</template>

<script>
import cal from 'generate-calendar-url';
import { DateTime } from 'luxon';

export default {
    props: {
        booking: Object
    },
    computed: {
        readableDate() {
            const date = DateTime.fromISO(this.booking.date + "T" + this.booking.time);
            return date.toLocaleString(DateTime.DATETIME_FULL)
        },
        event() {
            const date = DateTime.fromISO(this.booking.date + "T" + this.booking.time);
            const description = `Participant code: ${this.booking.participant_code}  \nZoom Meeting ID: ${this.booking.meeting_id}  \nZoom Meeting Password: ${this.booking.meeting_password}`;

            return {
                title: "Secret Project",
                start: date.toJSDate(),
                end: date.plus({ hour: 1 }).toJSDate(),
                location: `https://us04web.zoom.us/j/${this.booking.meeting_id}`,
                description
            }
        },
        googleEvent() {
            return cal.google(this.event);
        },
        iCalEvent() {
            return cal.ical(this.event);
        }
    }
}
</script>
