<template>
    <v-container>
        <h1><b>Step 2:</b> Consent</h1>
        <p>
            This section is to confirm that you are content to take part in this study, and are
            aware of the various aspects which we are asking you to provide. If you have any
            questions or are unsure, don't go any further just yet but contact the lead researcher
            to discuss further.
        </p>
        <p>
            <b>
                Even having given this consent, you still have the right to withdraw from the study
                at any point.
            </b>
        </p>
        <v-card v-for="(questionBlock, blockId) in questions" :key="blockId" outlined class="mb-4">
            <v-card-title>{{ blockId + 1 }}. {{ questionBlock.name }}</v-card-title>
            <v-card-text>
                <div v-for="(question, questionId) in questionBlock.questions" :key="blockId + '-' + questionId">
                    <v-row>
                        <v-col cols="12" sm="10" class="text--primary">
                            {{ question }}
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-radio-group row hide-details v-model="answers[blockId + '-' + questionId]">
                                <v-radio label="Yes" :value="1"></v-radio>
                                <v-radio label="No" :value="0"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-divider v-if="questionId != (questionBlock.questions.length - 1)"></v-divider>
                </div>
            </v-card-text>
        </v-card>
        <p>
            By entering your details below, you are stating that you are happy to take part in the
            Secret Project study.
        </p>
        <v-text-field v-model="name" label="Full Name" outlined></v-text-field>
        <v-text-field v-model="email" label="Email Address" outlined></v-text-field>
        <p class="red--text" v-if="!nameValid">Please enter your full name to continue.</p>
        <p class="red--text" v-if="!emailValid">Please enter a valid email address to continue.</p>
        <p class="red--text" v-if="answerCount != requiredAnswers">Please give consent to continue.</p>
    </v-container>
</template>

<script>
import questions from '@/assets/consent.json';

export default {
    data: () => ({
        questions,
        answers: {},
        name: "",
        email: ""
    }),
    computed: {
        answerCount() {
            return Object.values(this.answers).filter(x => x == 1).length;
        },
        requiredAnswers() {
            let total = 0;
            for (let qb = 0; qb < this.questions.length; qb++) {
                total += this.questions[qb].questions.length;
            }
            return total;
        },
        nameValid() {
            return !!this.name.trim() && ((this.name.trim().split(" ").length) > 1);
        },
        emailValid() {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(this.email.trim()).toLowerCase());
        }
    },
    methods: {
        updateConsent() {
            this.$emit("updateConsent", this.answerCount == this.requiredAnswers);
        }
    },
    watch: {
        answerCount: {
            deep: true,
            handler() { this.updateConsent() }
        },
        requiredAnswers: {
            deep: true,
            handler() { this.updateConsent() }
        },
        name: {
            deep: true,
            handler() { this.$emit("updateName", this.name.trim()); }
        },
        email: {
            deep: true,
            handler() { if (!this.emailValid) { return; } this.$emit("updateEmail", this.email.trim()); }
        }
    }
}
</script>
