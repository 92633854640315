<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-toolbar-title>Secret Project Bookings</v-toolbar-title>
    </v-app-bar>

    <v-main>
        <Loading v-if="loading"/>
        <div v-else>
            <Welcome v-if="step == 1"/>
            <StepOne v-if="step == 1" :bookings="bookings" @updateCohort="cohort = $event"/>
            <StepTwo v-if="step == 2" @updateConsent="consent = $event" @updateName="consentName = $event" @updateEmail="consentEmail = $event"/>
            <StepThree v-if="step == 3" @updateAnswers="about = $event"/>
            <StepFour v-if="step == 4 && booking" :booking="booking"/>

            <v-container>
                <v-btn class="float-right mb-4" color="primary" large :disabled="nextButtonDisabled" @click="nextStep" v-if="!loading && step != 4">Next</v-btn>
            </v-container>
        </div>
    </v-main>
  </v-app>
</template>

<script>
import Loading from './components/Loading.vue';
import Welcome from './components/Welcome.vue';
import StepOne from './components/StepOne.vue';
import StepTwo from './components/StepTwo.vue';
import StepThree from './components/StepThree.vue';
import StepFour from './components/StepFour.vue';
import * as api from './api';

export default {
  name: 'App',
  components: {
    Loading,
    Welcome,
    StepOne,
    StepTwo,
    StepThree,
    StepFour
  },
  data: () => ({
    loading: false,
    step: 1,
    bookings: [],
    cohort: "",
    consent: false,
    consentName: "",
    consentEmail: "",
    about: [],
    booking: null
  }),
  computed: {
    nextButtonDisabled() {
        if (this.step == 1) {
            return !this.cohort;
        } else if (this.step == 2) {
            return !this.consent || !this.consentName || !this.consentEmail;
        } else if (this.step == 3) {
            return this.about.length == 0;
        }

      return false;
    }
  },
  methods: {
    async fetch() {
        this.loading = true;
        this.bookings = await api.getBookings();
        this.loading = false;
    },
    nextStep() {
        if (this.step >= 4) { return; }
        if (this.step == 3) { this.createBooking(); }
        this.step++;
    },
    async createBooking() {
        this.loading = true;
        this.booking = await api.createBooking(this.cohort, this.consentName, this.consentEmail, this.about);
        this.loading = false;
    }
  },
  mounted() {
    this.fetch();
  }
};
</script>
