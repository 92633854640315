<template>
    <v-container>
        <h1><b>Step 3:</b> About You</h1>
        <p>
            We want to know a little bit about you so we can help understand why you might make
            certain decisions during the game.
        </p>
        <v-row align="center">
            <template v-for="(question, i) in questions">
                <v-col cols="12" sm="8" :key="i + 'prompt'">
                    <span class="d-block">{{ question.prompt }}</span>
                    <span class="caption d-block grey--text" v-if="question.help">{{ question.help }}</span>
                </v-col>
                <v-col cols="12" sm="4" :key="i + 'answer'">
                    <v-select :items="question.answers" outlined hide-details dense v-model="answers[i]"></v-select>
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>

<script>
import questions from '@/assets/about.json';

export default {
    data: () => ({
        questions,
        answers: []
    }),
    computed: {
        complete() {
            return this.answers.length == questions.length;
        }
    },
    watch: {
        answers: {
            deep: true,
            handler() {
                if (!this.complete) { return; }
                this.$emit("updateAnswers", this.answers);
            }
        }
    }
}
</script>
